<template>
  <section class="vista-formulario">
    <div
      :class="['tarjeta-form', {'registro': mostrarRegistro},
        {'recuperar-clave': mostrarEmail}, 'animated fadeInDown'
      ]"
    >
      <h1 class="h5 user-select-none">{{nombreAgencia.length ? nombreAgencia : 'Global Trackit System'}}</h1>
      <h3 class="my-3 user-select-none">{{ tituloFormulario }}</h3>
      <form @submit.prevent="submitForm">
        <div
          v-if="mostrarRegistro"
          :class="[{'campo': validarValores.nombre && validarValores.nombre.length},
            {'valido': validarValores.nombre === 'valido'},
            {'invalido': validarValores.nombre === 'invalido'}
          ]"
        >
          <mdb-input
            v-model.trim="input.nombre"
            label="Nombre completo"
            class="mt-4 mb-2"
            outline
          />
        </div>
        <div
          v-if="mostrarRegistro"
          :class="[{'campo': validarValores.telefono && validarValores.telefono.length},
            {'valido': validarValores.telefono === 'valido'},
            {'invalido': validarValores.telefono === 'invalido'},
          ]"
        >
          <mdb-input
            v-model.number="input.telefono"
            type="tel"
            label="Teléfono"
            placeholder="Ej: 58426573828"
            minlength="7"
            maxlength="12"
            class="plus-addon my-3"
            outline
          >
            <span class="input-group-text md-addon" slot="prepend">+</span>
          </mdb-input>
        </div>
        <div
          :class="[{'campo': validarValores.usuario && validarValores.usuario.length},
            {'valido': validarValores.usuario === 'valido'},
            {'invalido': validarValores.usuario === 'invalido'}
          ]"
        >
          <mdb-input
            v-model.trim="input.usuario"
            type="email"
            :label="mostrarEmail || mostrarRegistro ? 'Email' : 'Usuario'"
            :class="mostrarRegistro ? 'my-3' : 'mt-4 mb-3'"
            outline
          />
        </div>
        <div
          v-if="mostrarRegistro"
          :class="[{'campo': validarValores.identificacion
              && validarValores.identificacion.length},
            {'valido': validarValores.identificacion == 'valido'},
            {'invalido': validarValores.identificacion == 'invalido'},
          ]"
        >
          <mdb-input
            v-model.trim="input.identificacion"
            label="Número de ID"
            class="my-2"
            outline
          />
        </div>
        <div
          v-if="mostrarRegistro"
          :class="[{'campo': validarValores.genero
              && validarValores.genero.length},
            {'valido': validarValores.genero == 'valido'},
            {'invalido': validarValores.genero == 'invalido'},
          ]"
        >
          <div class="md-form md-outline outline-select my-2">
            <select
              id="genero-select"
              v-model="input.genero"
              class="custom-select"
            >
              <option class="d-none" disabled value="">
                Seleccione
              </option>
              <option
                v-for="(value, key) in ClienteGeneros"
                :key="value+'genero'"
                :value="key"
              >
              {{ value }}
              </option>
            </select>
            <label
              for="genero-select"
              :class="input.genero ? 'label-active' : 'label-inactive'"
            >
              Género
            </label>
          </div>
        </div>
        <div
          v-if="!mostrarEmail"
          :class="[{'campo': validarValores.clave && validarValores.clave.length},
            {'valido': validarValores.clave === 'valido'},
            {'invalido': validarValores.clave === 'invalido'},
          ]"
        >
          <mdb-input
            v-model.trim="input.clave"
            :type="mostrarClave ? 'text' : 'password'"
            label="Clave"
            :class="[{'mt-2': mostrarRegistro}, {'heightInInput': !mostrarEmail}]"
            outline
          >
            <mdb-btn
              slot="append"
              group
              flat
              dark-waves
              size="md"
              :icon="mostrarClave ? 'eye-slash' : 'eye'"
              :title="mostrarClave ? 'Ocultar clave' : 'Mostrar clave'"
              @click="mostrarClave = !mostrarClave"
            />
          </mdb-input>
        </div>
        <mdb-btn
          v-if="mostrarEmail"
          role="button"
          color="terciario"
          :icon="botonDeshabilitado ? 'circle-notch' : 'lock'"
          :icon-class="botonDeshabilitado ? 'fa-spin' : ''"
          :disabled="botonDeshabilitado"
          class="px-3"
          @click="recuperarClaveCliente"
        >
          Recuperar clave
        </mdb-btn>
        <mdb-btn
          v-else-if="mostrarRegistro"
          role="button"
          color="terciario"
          :icon="botonDeshabilitado ? 'circle-notch' : 'user-plus'"
          :icon-class="botonDeshabilitado ? 'fa-spin' : ''"
          :disabled="botonDeshabilitado"
          class="px-3"
          @click="registroCliente"
        >
          Registrarme
        </mdb-btn>
        <mdb-btn
          v-else
          type="submit"
          role="button"
          color="primario"
          :icon="botonDeshabilitado ? 'circle-notch' : 'sign-in-alt'"
          :icon-class="botonDeshabilitado ? 'fa-spin' : ''"
          :disabled="botonDeshabilitado"
          class="px-3"
        >
          Iniciar sesión
        </mdb-btn>
        <div class="row justify-content-center mt-2">
          <mdb-btn
            flat
            dark-waves
            size="sm"
            :icon="mostrarEmail ? 'angle-left' : 'lock'"
            :disabled="botonDeshabilitado"
            :class="['px-2', {'order-2': mostrarRegistro}]"
            @click="mostrarBotonRecuperaClave"
          >
            {{ mostrarEmail ? 'Volver' : 'Recuperar clave' }}
          </mdb-btn>
          <mdb-btn
            v-if="mostrarRegistro"
            flat
            dark-waves
            size="sm"
            icon="angle-left"
            :disabled="botonDeshabilitado"
            :class="['px-2', {'order-1': mostrarRegistro}]"
            @click="mostrarBotonRegistro"
          >
            Volver
          </mdb-btn>
        </div>
      </form>
    </div>
    <AlertaMensaje
      :alerta-mensaje="alertaMensaje"
      @cerrar="alertaMensaje.contenido = ''"
    />
  </section>
</template>

<script>
import {
  mdbBtn,
  mdbInput
} from 'mdbvue'
import AlertaMensaje from '@/components/AlertaMensaje.vue'
import clienteLogin from '@/graphql/clienteLogin.gql'
import recuperarClaveGql from '@/graphql/recuperarClave.gql'
import { capitalizar, validarMail, validarTel } from '@/funciones/funciones.js'
import clienteUltimoConCasilleroGql from '@/graphql/clienteUltimoConCasillero.gql'
import { ClienteGeneros } from '@/constantes/clientes.js'
import clienteRegistro from '@/graphql/clienteRegistro.gql'
import existeAgenciaPorCodigoGql from '@/graphql/existeAgenciaPorCodigo.gql'
import { onLogin } from '@/vue-apollo.js'
export default {
  name: 'Login',
  components: {
    mdbBtn,
    mdbInput,
    AlertaMensaje
  },
  data () {
    return {
      nombreAgencia: '',
      alertaMensaje: { contenido: '' },
      ClienteGeneros,
      botonDeshabilitado: false,
      input: {
        usuario: '',
        clave: '',
        nombre: '',
        telefono: '',
        identificacion: '',
        genero: ''
      },
      mostrarClave: false,
      mostrarEmail: false,
      mostrarRegistro: false,
      codigoAgencia: this.$route.params.codigoAgencia || false,
      validarValores: {}
    }
  },
  async mounted () {
    if (this.codigoAgencia && this.codigoAgencia.length >= 2 && this.codigoAgencia.length <= 10) {
      this.codigoAgencia = this.codigoAgencia.toLowerCase()
      const existeAgencia = await this.verificarCodigoAgencia()
      if (!existeAgencia) {
        this.alertaMensaje = {
          contenido: 'El código de la agencia no existe. Verifique nuevamente',
          tipo: 'advertencia'
        }
        return
      } else {
        this.nombreAgencia = existeAgencia[1]
      }
      this.mostrarRegistro = true
    }
  },
  methods: {
    submitForm () {
      this.validarValores = {
        usuario: this.input.usuario !== '' ? 'valido' : 'invalido',
        clave: this.input.clave !== '' ? 'valido' : 'invalido'
      }
      if (Object.values(this.validarValores).includes('invalido')) {
        this.alertaMensaje = {
          contenido: 'Todos los campos son requeridos',
          tipo: 'advertencia'
        }
        return
      }
      this.botonDeshabilitado = true
      this.input.usuario = this.input.usuario.toLowerCase()
      this.$apollo
        .mutate({
          mutation: clienteLogin,
          variables: {
            usuario: this.input.usuario,
            clave: this.input.clave
          }
        })
        .then(({ data: { clienteLogin } }) => {
          const { codigo, token } = clienteLogin
          switch (codigo) {
            case 'Correcto':
              onLogin(this.$apolloProvider.clients.defaultClient, token)
              this.$router.push('/')
              break
            case 'ClaveInvalida':
              this.alertaMensaje = {
                contenido: 'Clave inválida',
                tipo: 'error'
              }
              this.validarValores.clave = 'invalido'
              break
            case 'UsuarioInvalido':
              this.alertaMensaje = {
                contenido: 'Usuario inválido',
                tipo: 'error'
              }
              this.validarValores.usuario = 'invalido'
              break
            case 'UsuarioInactivo':
              this.alertaMensaje = {
                contenido: 'Tu cuenta no ha sido verificada, debes ingresar el código enviado a tu correo',
                tipo: 'error'
              }
              this.validarValores.usuario = 'invalido'
              setTimeout(() => {
                this.$router.push(`/activar-cliente/${this.input.usuario}`)
              }, 3000)
              break
            case 'CambioClave':
              this.alertaMensaje = {
                contenido: 'Debes cambiar tu clave. Serás redirigido al formulario para cambiar la clave.',
                tipo: 'error'
              }
              this.validarValores.clave = 'invalido'
              setTimeout(() => {
                this.$router.push(`/recuperar-clave/${this.input.usuario}`)
              }, 3000)
              break
            default:
              this.alertaMensaje = {
                contenido: 'Error desconocido',
                tipo: 'error'
              }
              break
          }
          this.botonDeshabilitado = false
        })
        .catch((e) => {
          this.alertaMensaje = {
            contenido: `Error: ${e}`,
            tipo: 'error'
          }
          this.mostrarEmail = false
          this.botonDeshabilitado = false
        })
    },
    mostrarBotonRegistro () {
      if (this.codigoAgencia) {
        this.mostrarRegistro = !this.mostrarRegistro
      }
      this.mostrarEmail = false
      this.valoresIniciales()
    },
    mostrarBotonRecuperaClave () {
      this.mostrarEmail = !this.mostrarEmail
      this.mostrarRegistro = false
      this.valoresIniciales()
    },
    async registroCliente () {
      this.validarValores = {
        nombre: this.input.nombre && this.input.nombre.length ? 'valido' : 'invalido',
        clave: this.input.clave !== '' ? 'valido' : 'invalido',
        usuario: this.input.usuario !== '' ? 'valido' : 'invalido',
        genero: this.input.genero !== '' ? 'valido' : 'invalido',
        identificacion: parseFloat(this.input.identificacion) ? 'valido' : 'invalido',
        telefono: this.input.telefono && parseFloat(this.input.telefono) ? 'valido' : 'invalido'
      }
      if (Object.values(this.validarValores).includes('invalido')) {
        this.alertaMensaje = {
          contenido: 'Todos los campos son requeridos',
          tipo: 'advertencia'
        }
        return
      }
      this.input.nombre = capitalizar(this.input.nombre)
      if (!validarTel(this.input.telefono)) {
        this.validarValores.telefono = 'invalido'
        return (this.alertaMensaje = {
          contenido: 'Teléfono no válido',
          tipo: 'error'
        })
      }
      const esValido = validarMail(this.input.usuario)
      this.input.usuario = this.input.usuario.toLowerCase()
      if (!esValido) {
        this.validarValores.usuario = 'invalido'
        this.alertaMensaje = {
          contenido: 'El email ingresado no es válido',
          tipo: 'error'
        }
        return
      }
      // retorna el id de la agencia o null
      const resultado = await this.verificarCodigoAgencia()
      if (!resultado) {
        this.alertaMensaje = {
          contenido: 'El código de agencia no es válido',
          tipo: 'error'
        }
        return
      }
      // Obtener casillero del cliente a registrar
      let casillero = ''
      // Asignación automática de casillero
      const casilleroPrefijo = resultado[2]
      const casilleroAnterior = this.clienteUltimoConCasillero.casillero
      if (casilleroAnterior && casilleroAnterior.length) {
        const casilleroNumeroAnterior = casilleroAnterior.replace(/[^\d]/g, '')
        if (casilleroNumeroAnterior && casilleroNumeroAnterior.length) {
          const casilleroNumeroNuevo = Number(casilleroNumeroAnterior) + 1
          casillero = casilleroPrefijo + String(casilleroNumeroNuevo)
        }
      } else {
        this.alertaMensaje = {
          contenido: 'Ha ocurrido un error procesando el código de la agencia, revisa tu link de registro y prueba de nuevo.',
          tipo: 'error'
        }
        return
      }
      this.input.usuario = this.input.usuario.toLowerCase()
      this.botonDeshabilitado = true
      this.$apollo.mutate({
        mutation: clienteRegistro,
        variables: {
          cliente: {
            nombre: this.input.nombre,
            casillero,
            clave: this.input.clave,
            identificacion: this.input.identificacion,
            genero: this.input.genero
          },
          agenciaId: resultado[0],
          emails: [this.input.usuario],
          telefonos: [String(this.input.telefono)]
        }
      })
        .then(({ data: { clienteRegistro } }) => {
          if (clienteRegistro.id.length && !clienteRegistro.acceso) {
            this.alertaMensaje = {
              contenido: 'Registrado correctamente, debes activar tu cuenta para poder iniciar sesión',
              tipo: 'advertencia'
            }
            setTimeout(() => {
              this.botonDeshabilitado = false
              this.$router.push(`/activar-cliente/${this.input.usuario}`)
              this.validarValores = {}
              this.input = {
                usuario: '',
                clave: '',
                email: '',
                telefono: ''
              }
            }, 2500)
          } else if (clienteRegistro.id.length && clienteRegistro.acceso) {
            this.alertaMensaje = {
              contenido: 'Este cliente ya se encuentra registrado',
              tipo: 'error'
            }
            this.validarValores = {}
            this.input = {
              usuario: '',
              clave: '',
              email: ''
            }
            this.botonDeshabilitado = false
          } else if (!clienteRegistro.id.length && clienteRegistro.acceso) {
            this.alertaMensaje = {
              contenido: 'Este cliente ya se encuentra relacionado con una agencia',
              tipo: 'error'
            }
            this.validarValores = {}
            this.input = {
              usuario: '',
              clave: '',
              email: ''
            }
            this.botonDeshabilitado = false
          } else {
            this.alertaMensaje = {
              contenido: 'Ha ocurrido un error en el proceso de registro',
              tipo: 'error'
            }
            this.botonDeshabilitado = false
          }
        })
        .catch(e => {
          this.alertaMensaje = {
            contenido: `Error: ${e}`,
            tipo: 'error'
          }
          this.mostrarEmail = false
          this.botonDeshabilitado = false
          this.valoresIniciales()
        })
    },
    recuperarClaveCliente () {
      this.validarValores = {
        usuario: this.input.usuario !== '' ? 'valido' : 'invalido'
      }
      if (Object.values(this.validarValores).includes('invalido')) {
        this.alertaMensaje = {
          contenido: 'Todos los campos son requeridos',
          tipo: 'advertencia'
        }
        return
      }
      const esValido = validarMail(this.input.usuario)
      if (!esValido) {
        this.validarValores.usuario = 'invalido'
        this.alertaMensaje = {
          contenido: 'El email ingresado no es válido',
          tipo: 'error'
        }
        return
      }
      this.botonDeshabilitado = true
      this.$apollo.mutate({
        mutation: recuperarClaveGql,
        variables: {
          usuario: this.input.usuario
        }
      })
        .then(({ data: { personaRecuperarClave } }) => {
          const { codigo } = personaRecuperarClave
          switch (codigo) {
            case 'Correcto':
              break
            case 'UsuarioInvalido':
              this.alertaMensaje = {
                contenido: 'Usuario no registrado',
                tipo: 'error'
              }
              this.botonDeshabilitado = false
              this.validarValores.usuario = 'invalido'
              this.input = {
                usuario: '',
                clave: '',
                email: '',
                telefono: ''
              }
              return
            default:
              this.alertaMensaje = {
                contenido: 'Error desconocido',
                tipo: 'error'
              }
              break
          }
          this.alertaMensaje = {
            contenido: 'Código de confirmación enviado a tu correo, debes ingresarlo para iniciar sesión',
            tipo: 'advertencia'
          }
          setTimeout(() => {
            this.botonDeshabilitado = false
            this.$router.push(`/recuperar-clave/${this.input.usuario}`)
            this.validarValores = {}
            this.input = {
              usuario: '',
              clave: '',
              email: '',
              telefono: ''
            }
          }, 2500)
          this.botonDeshabilitado = false
        })
        .catch(() => {
          this.alertaMensaje = {
            contenido: 'Ha ocurrido un error al recuperar el usuario. Por favor intenta de nuevo',
            tipo: 'error'
          }
          this.mostrarEmail = false
          this.botonDeshabilitado = false
          this.valoresIniciales()
        })
    },
    valoresIniciales () {
      this.mostrarClave = false
      this.input = { usuario: '', clave: '', nombre: '', telefono: '' }
      this.validarValores = {}
    },
    async verificarCodigoAgencia () {
      if (!this.codigoAgencia) return
      try {
        const { data: { existeAgenciaPorCodigo } } = await this.$apollo.query({
          query: existeAgenciaPorCodigoGql,
          variables: {
            codigo: this.codigoAgencia
          }
        })
        return existeAgenciaPorCodigo
      } catch (error) {
        this.alertaMensaje = {
          contenido: 'Ha ocurrido un error al verificar el código de la agencia',
          tipo: 'error'
        }
      }
    }
  },
  computed: {
    tituloFormulario () {
      return this.mostrarEmail
        ? 'Recuperar clave' : this.mostrarRegistro
          ? 'Registro cliente' : 'Login cliente'
    }
  },
  apollo: {
    clienteUltimoConCasillero () {
      return {
        query: clienteUltimoConCasilleroGql,
        variables: () => {
          return {
            orderBy: ['creadoEl_desc'],
            filter: {
              casillero_not: null
            },
            offset: 0,
            first: 1
          }
        },
        update: (data) => data.Cliente[0],
        fetchPolicy: 'cache-and-network'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.campo {
  margin-bottom: inherit;
}
.heightInInput {
  animation-duration: .6s;
  animation-fill-mode: both;
  animation-iteration-count: 1;
  animation-name: inputIn;
  animation-timing-function: ease;
}
@keyframes inputIn {
  from {
    height: 0;
    opacity: 0;
  }
  to {
    height: 34px;
    opacity: 1;
  }
}
</style>
