// Exporta el PaqueteEstatus definido en data
const ClienteGeneros = {
  Masculino: 'Masculino',
  Femenino: 'Femenino',
  Otro: 'Otro'
}

export {
  ClienteGeneros
}
